.appWrapper {
  background-color: var(--appBackground);
  transition: background-color var(--transitionTime) ease;
}
.desktopPadding {
  padding: 0 7.5%;
}
.mobilePadding {
  padding: 0;
  position: relative;
}

.homeNav {
  background-color: var(--homeNavBackground);
  transition: background-color var(--transitionTime) ease;
  padding: 8px 16px;
}
.homeNavCollapse {
  justify-content: end;
}

.navLink {
  padding: 16px;
  color: rgba(255, 255, 255, 0.5);
  text-decoration: none;
}
.navLink:hover,
.navLink:focus {
  color: white;
}

.skipNavigationButton {
  border: 2.5px solid white;
  background-color: transparent;
  border-radius: 0;
  min-width: 275px;
  width: 10%;
  font-size: 1.25rem;
  font-weight: bold;
  top: 7.5px;
  left: 7.5px;
  position: absolute;
  transform: translateY(-120%);
}
.skipNavigationButton:focus {
  transform: translateY(0);
  color: var(--hoverColor);
  background-color: transparent;
  border-color: var(--hoverColor);
}
.skipNavigationButton:hover {
  color: var(--hoverColor);
  background-color: transparent;
  border-color: var(--hoverColor);
}
