.contactWrapper {
  padding: 25px 45px;
  background-color: var(--contactBackground);
  transition: background-color var(--transitionTime) ease;
}
.contactWrapper.mobile {
  padding: 30px 15px;
}

.contactLeftCol {
  text-align: center;
  font-weight: bold;
  color: var(--contactTextLeft);
  text-transform: uppercase;
  padding: 20px;
  transition: color var(--transitionTime) ease;
}
.contactRightCol {
  color: var(--contactTextRight);
  padding: 20px;
  transition: color var(--transitionTime) ease;
}
.contactLink {
  color: var(--contactTextRight);
  text-decoration: underline;
  transition: color var(--transitionTime) ease;
}
