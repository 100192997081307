.partWrapper {
  background-color: transparent;
  text-align: center;
  height: 100%;
  padding: 64px 32px;
}

.image {
  width: 75%;
  max-width: 300px;
  padding: 20px;
  border-radius: 1.5rem;
}
.image.spotlight {
  max-width: 500px;
}

.dateText {
  font-size: 1.2rem;
  font-style: italic;
  padding-bottom: 7.5px;
}
.titleText {
  font-size: 2.1rem;
  font-weight: 350;
  padding-bottom: 15px;
}
.locationText {
  font-size: 1.75rem;
}
.subtitleText {
  font-size: 1.45rem;
  font-weight: bold;
}
.descriptionText {
  font-size: 18px;
  font-weight: 200;
  line-height: 165%;
  padding-bottom: 20px;
}

.partLink {
  color: inherit;
  display: flex;
  justify-content: center;
  transition: inherit;
}
.partLink:hover,
.partLink:focus {
  color: var(--hoverColor);
}
.partLink.inactive {
  pointer-events: none;
}

.partButton {
  color: inherit;
  border: 3px solid;
  background-color: transparent;
  border-radius: 0px;
  font-size: 1.5rem;
  font-weight: bold;
  text-transform: uppercase;
  transition: inherit;
}
.partButton:hover,
.partButton:focus {
  color: var(--hoverColor);
  background-color: transparent;
  border-color: var(--hoverColor);
}

.descriptionWrapper {
  padding-bottom: 5px;
}
.accordionWrapper {
  background-color: transparent;
  border: none;
}
.accordion-body {
  padding: 0;
}
.accordion-button {
  background-color: transparent;
  color: inherit;
  display: flex;
  font-size: 22px;
  justify-content: center;
  transition: inherit;
}
.accordion-button:not(.collapsed) {
  color: inherit;
  background-color: transparent;
  box-shadow: none;
}
.accordion-button::after {
  position: relative;
  margin-left: 5px;
  transition: background-image var(--transitionTime) ease,
    transform 0.2s ease-in-out;
}
.accordion-button::after,
.accordion-button:not(.collapsed)::after {
  background-image: var(--arrowDropdownIcon);
}
.accordion-button:focus {
  box-shadow: none;
  border-color: rgba(0, 0, 0, 0.125);
}
.accordion-inner {
  border-top: 0 none;
}
