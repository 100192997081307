.buttonToolbar {
  filter: grayscale(25%);
}
.socialButton {
  background-color: transparent;
  border: 0;
}
.socialButton:hover,
.socialButton:focus {
  background-color: transparent;
}
.iconLink:hover,
.iconLink:focus {
  fill: var(--hoverColor);
}
