.customErrorWrapper {
  background-color: var(--sectionBackground);
  color: var(--sectionText);
  text-align: center;
  margin-bottom: 0;
  transition: background-color var(--transitionTime) ease;
  transition: color var(--transitionTime) ease;
  padding: 2rem 1rem;
}
.errorText {
  font-size: 1.75rem;
}
.errorButton {
  display: inline-block;
  padding: 1rem;
  text-decoration: underline;
  color: var(--sectionText);
}
.errorButton:hover,
.errorButton:focus {
  color: var(--hoverColor);
}

.errorImage {
  width: 75%;
  max-width: 500px;
  padding: 20px;
  border-radius: 1.5rem;
}
