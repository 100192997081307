.sidebarWrapper {
  position: relative;
  z-index: 1;
}

.menuButton {
  position: fixed;
  top: 10px;
  right: 10px;
  border: 0;
  border-radius: 0px;
  background-color: rgba(255, 255, 255, 0.9);
  color: black;
  font-size: 18px;
  text-transform: uppercase;
  line-height: 25px;
}
.menuButton:hover,
.menuButton:focus {
  background-color: var(--hoverColor);
}
.menuButton.boxShadow {
  box-shadow: 3px 3px 3px 3px var(--sidebarBoxShadow);
}

.menuIcon {
  vertical-align: text-top;
  padding-top: 3px;
  padding-right: 5px;
}

.navOverlay {
  width: 100%;
  background-color: var(--sidebarOverlay);
  height: 100vh;
  position: fixed;
  top: 0;
  opacity: 0;
  pointer-events: none;
}
.navOverlay.active {
  opacity: 0.75;
  pointer-events: auto;
  transition: 350ms;
}
.navOverlay.inactive {
  opacity: 0;
  pointer-events: none;
  transition: 350ms;
}

.navMenu {
  background-color: var(--sidebarBackground);
  width: 250px;
  height: 100vh;
  display: flex;
  position: fixed;
  top: 0;
  right: -100%;
  flex-direction: column;
  align-items: flex-start;
  transition: background-color var(--transitionTime) ease;
}
.navMenu.active {
  right: 0;
  transition: 350ms;
}
.navMenu.inactive {
  right: -100%;
  transition: 350ms;
}

.navText {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
  height: 60px;
}
.navText a {
  text-decoration: none;
  color: var(--sidebarText);
  padding-left: 20px;
  font-size: 18px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: 0px;
  transition: color var(--transitionTime) ease;
}
.navText a:hover,
.navText a:focus {
  color: black;
  background-color: var(--sidebarHover);
}

.navMenuItems {
  width: 100%;
  padding: 0;
  list-style-type: none;
}

.navbarToggle {
  color: white;
  width: 100%;
  height: 50px;
  display: flex;
  padding-top: 8px;
  padding-left: 18px;
  justify-content: flex-start;
  align-items: center;
}
