.themeSwitchWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.themeSwitchWrapper.mobile {
  padding: 0 20px;
}

.themeToggle {
  width: 50px;
  margin: 0px 20px;
  font-size: 18px;
  color: rgb(255, 123, 0);
  border-color: var(--hoverColor);
}
.themeToggle.mobile {
  margin: 0px 10px;
}

* .form-switch .form-check-input {
  width: 35px;
  margin-top: 4px;
}
* .form-check-input:checked {
  color: white;
  border-color: var(--hoverColor);
  background-color: var(--hoverColor);
}
