.sectionWrapper {
  background-color: var(--sectionBackground);
  color: var(--sectionText);
  transition: color var(--transitionTime) ease,
    background-color var(--transitionTime) ease;
}
.sectionRow {
  margin: 0;
}
.colLeft {
  border-right: 2px solid var(--sectionDivider);
  border-bottom: 2px solid var(--sectionDivider);
  padding: 0;
}
.colLeft.bottom {
  border-bottom: none;
}
.colRight {
  border-bottom: 2px solid var(--sectionDivider);
  padding: 0;
}
.colRight.bottom {
  border-bottom: none;
}
